<!--协会介绍-->
<template>
  <div class="messageDiv">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title">{{title}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="news_time_reader">{{time}}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <div class="w-e-text-container">
            <div v-html="newsData" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
      <!--协会介绍去掉文章标签-->
      <!--<el-row>
        <el-col :span="24" class="label_contain">
          <div class="news_labels">文章标签</div>
          <div>
            <div class="label_div">
              <span class="label_span">{{labelList}}</span>
            </div>
          </div>
        </el-col>
      </el-row>-->
    </div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import {getPageByCategoryId, getDetailByd} from '@/utils/commonApi.js'
export default {
  name: "introduce",
  components: { topPathList},
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标协会',

        },
        {
          name: '协会介绍',
          path: '/giia/introduce'
        },
      ],
      title: '山东省地理标志产业协会',
      newsData: '',
      time: '',
      contentId: '',
      labelList: '',
    }
  },
  methods: {
    //根据栏目id查询文章列表
    getList() {
      let params = {
        ...this.$route.query,
        current: 1,
        size: 10,
      }
      getPageByCategoryId(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.contentId = res.data.data.records[0].contentId
          this.getDetail()
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //根据文章id查询文章详情
    getDetail() {
      let params = {
        contentId: this.contentId
      }
      getDetailByd(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.title = res.data.data.contentTitle //标题
          this.time = res.data.data.contentEditTime.substring(0,10) //日期
          this.newsData = res.data.data.contentDetail //内容
          this.labelList = res.data.data.keyword //文章标签
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .messageDiv {
    background-color: #f8f9fa !important;
    padding-bottom: 64px;
  }
  .breadTopDiv {
    text-align: center;
    .breadTop {
      width: 1140px;
      margin: auto;
    }
  }
  .content-div {
    min-height: 481px;
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1040px;
    background: #FFFFFF;
    .titleDiv {
      text-align: center;
    }
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
  }
  .news_time_reader {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #888888;
    line-height: 21px;
  }
  .news_labels {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #3E3A39;
    line-height: 24px;
  }
  .label_contain {
    display: flex;
    align-items: center;
  }
  .label_div {
    background: #E9F2FF;
    border-radius: 6px;
    margin: 5px;
    display: flex;
    float: left;

    .label_span {
      padding: 5px 10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #186CF5;
      line-height: 28px;
    }
  }

  ::v-deep .el-row {
    margin: 20px 0;
    padding: 0 10px;
  }

  ::v-deep .temp_font {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 30px;
    text-indent: 2rem;
  }

  @media screen and (max-width: 1366px) {
    .messageDiv {
      background-color: #f8f9fa !important;
      padding-bottom: 64px;
    }
    .breadTop {
      width: 1140px;
      margin: auto;
    }
    .content-div {
      margin: auto;
      padding: 32px 40px 12px 40px;
      width: 1040px;
      background: #FFFFFF;
      .titleDiv {
        text-align: center;
      }
    }
    .news_title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
    }
    .news_time_reader {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #888888;
      line-height: 21px;
    }
    .news_labels {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #3E3A39;
      line-height: 24px;
    }
    .label_contain {
      display: flex;
      align-items: center;
    }
    .label_div {
      background: #E9F2FF;
      border-radius: 6px;
      margin: 5px;
      display: flex;
      float: left;

      .label_span {
        padding: 5px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #186CF5;
        line-height: 28px;
      }
    }

    ::v-deep .el-row {
      margin: 20px 0;
      padding: 0 10px;
    }

    ::v-deep .temp_font {
      text-align: left;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #3E3A39;
      line-height: 30px;
      text-indent: 2rem;
    }
  }
</style>
